import React, { Component } from "react";
import { connect } from "react-redux";
class FullPageLoader extends Component {
    state = {};

    render() {
        const { loading } = this.props;

        if (!loading) return null;

        return (
            <div className="loader-div">
                <div className="loadingio-spinner-ripple-5xrpd0k7ecq">
                    <div className="ldio-9uukc0wm668">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ loading: state.loaderReducer.loading })

export default connect(mapStateToProps)(FullPageLoader);
